import { ref, Ref, UnwrapRef, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';

const save = useDebounceFn(
	() => Office.context.roamingSettings.saveAsync(),
	100,
);

export function useRoamingSetting<T>(name: string): Ref<T | undefined>;
export function useRoamingSetting<T>(name: string, defaultValue: T): Ref<T>;
export function useRoamingSetting<T>(
	name: string,
	defaultValue?: T,
): Ref<UnwrapRef<T> | undefined> {
	const v = getSetting(name, defaultValue);
	const setting = ref(v);

	const isBool = typeof defaultValue === 'boolean';
	watch(setting, (s) => {
		const sc = isBool ? (s ? 'true' : 'false') : s;
		Office.context.roamingSettings.set(name, sc);
		save();
	});

	return setting;
}
export function getSetting<T>(name: string): T | undefined;
export function getSetting<T>(name: string, defaultValue: T): T;
export function getSetting<T>(name: string, defaultValue?: T): T | undefined {
	const loadedValue = Office.context.roamingSettings.get(name);
	if (loadedValue === undefined) return defaultValue;

	const isBool = typeof defaultValue === 'boolean';
	return isBool && typeof loadedValue === 'string'
		? loadedValue === 'true'
			? true
			: false
		: loadedValue;
}
