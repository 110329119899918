export async function getAutoDiscoveredEndpoint(
	endpoint: string,
): Promise<string> {
	try {
		const body = await fetch(endpoint + '/autodiscover.json');
		const res = await body.json();
		if (typeof res === 'object' && Object.keys(res).includes('API_URL'))
			endpoint = res.API_URL;
	} catch {
		// No autodiscover, use actual endpoint
	}
	return endpoint;
}
