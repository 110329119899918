import { Awaitable, useTimeoutPoll } from '@vueuse/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ref } from 'vue';

type Item = typeof Office.context.mailbox.item;

const item = ref<Item>();
export const itemId = ref<string>('');
export const subject = ref<string>('');
export const archiveReference = ref<string>();
export const externalEmailAddresses = ref<string[]>([]);
export const externalEmailAddressDetails = ref<
	{ emailAddress: string; displayName: string }[]
>([]);
export const attachmentList = ref<{ id: string; name: string }[]>([]);

export function registerItemChanged() {
	Office.context.mailbox.addHandlerAsync(
		Office.EventType.ItemChanged,
		itemChanged,
	);
	itemChanged();
}

function itemChanged() {
	const i = Office.context.mailbox.item;
	item.value = i;
	setItemId();
	setSubject();
	setExternalEmailAddress();
	setAttachmentList();
	setArchiveReference();
}

export function registerRecipientsChanged() {
	if (Office.context.mailbox.item)
		Office.context.mailbox.item.addHandlerAsync(
			Office.EventType.RecipientsChanged,
			({ changedRecipientFields }: Office.RecipientsChangedEventArgs) => {
				if (changedRecipientFields.to) setExternalEmailAddress();
			},
		);
}

export function registerAttachmentsChanged() {
	if (Office.context.mailbox.item)
		Office.context.mailbox.item.addHandlerAsync(
			Office.EventType.AttachmentsChanged,
			() => {
				setAttachmentList();
			},
		);
}

let subjectPollFn: Maybe<() => Awaitable<void>> = undefined;
const poller = useTimeoutPoll(() => {
	if (subjectPollFn) subjectPollFn();
}, 1000);
poller.resume();

function setItemId(): void {
	const i = Office.context.mailbox.item;
	if (i?.getItemIdAsync) {
		i?.getItemIdAsync((res) => {
			itemId.value = res.value || '';
			if (res.value) return;
			i.saveAsync(() => {
				setItemId();
			});
		});
	} else {
		itemId.value = i?.itemId || '';
	}
}

function setSubject(): void {
	const i = Office.context.mailbox.item;
	if (i?.subject.getAsync) {
		subjectPollFn = async () => {
			await new Promise<void>((resolve) => {
				if (Office.context.mailbox.item)
					i?.subject.getAsync(({ value }) => {
						if (subject.value !== value) subject.value = value;
						resolve();
					});
			});
		};
	} else {
		subject.value = i?.subject || '';
	}
}

function setArchiveReference(): void {
	archiveReference.value = undefined;
	getArchiveReference().then((res) => {
		archiveReference.value = res;
	});
}

export function getArchiveReference(): Promise<string | undefined> {
	return new Promise((resolve) => {
		const i = Office.context.mailbox.item;
		if (!i) {
			resolve(undefined);
			return;
		}

		i.loadCustomPropertiesAsync((r) => {
			if (!r.value) {
				resolve(undefined);
				return;
			}
			resolve(r.value.get('archiveReference'));
		});
	});
}

function setExternalEmailAddress(): void {
	const i = Office.context.mailbox.item;
	if (!i) {
		externalEmailAddresses.value = [];
		externalEmailAddressDetails.value = [];
		return;
	}
	const process = (to: Office.EmailAddressDetails[]) => {
		const c = determineCommunication(i, to);
		if (c === undefined) {
			externalEmailAddresses.value = [];
			externalEmailAddressDetails.value = [];
		} else if (c === 'Outgoing') {
			externalEmailAddresses.value = to.map((it) => it.emailAddress);
			externalEmailAddressDetails.value = [...to];
		} else {
			externalEmailAddresses.value = [i.sender.emailAddress];
			externalEmailAddressDetails.value = [i.sender];
		}
	};
	if (!i.to.getAsync) {
		process(i.to);
		return;
	}
	i.to.getAsync((res) => {
		if (res.error) throw res;
		process(res.value);
	});
}

function setAttachmentList(
	attachments?: { isInline?: boolean; id: string; name: string }[],
): void {
	const i = Office.context.mailbox.item;
	if (!i) {
		attachmentList.value = [];
		return;
	}

	if (attachments === undefined) {
		if (i.attachments !== undefined) {
			attachments = i.attachments;
		} else {
			i.getAttachmentsAsync((res) => {
				if (res.error || res.value.length === 0) attachmentList.value = [];
				else setAttachmentList(res.value);
			});
			return;
		}
	}

	if (!attachments) {
		attachmentList.value = [];
		return;
	}
	attachmentList.value = attachments
		.filter((a) => !a.isInline)
		.map((a) => ({ id: a.id, name: a.name }));
}

export async function getCommunication(item?: Item) {
	if (!item) item = Office.context.mailbox.item;
	if (!item?.to.getAsync) return determineCommunication(item);
	else return await determineCommunicationAsync(item);
}

function determineCommunication(
	i?: Item,
	to?: { emailAddress: string }[],
): 'Incoming' | 'Outgoing' | undefined {
	if (!i) return undefined;
	if (to === undefined) to = i.to;

	if (!i.sender && to.length == 0) return undefined;
	if (
		to.length > 0 &&
		(!i.sender ||
			i.sender.emailAddress === Office.context.mailbox.userProfile.emailAddress)
	)
		return 'Outgoing';
	if (!i.sender) return undefined;
	return 'Incoming';
}

function determineCommunicationAsync(
	i: Item,
): Promise<'Incoming' | 'Outgoing' | undefined> {
	return new Promise((resolve, reject) => {
		if (!i) return resolve(undefined);
		i.to.getAsync((res) => {
			if (res.error) return reject(res);
			resolve(determineCommunication(i, res.value));
		});
	});
}
