import { FunctionsMap } from 'apollo-link-scalars';
import { parseISO, formatISO } from 'date-fns';

export const typesMap: FunctionsMap = {
	Date: {
		serialize: (parsed: unknown): string | null =>
			parsed instanceof Date
				? formatISO(parsed, { representation: 'date' })
				: null,
		parseValue: (raw: unknown): Date | null => {
			if (!raw) return null; // if for some reason we want to treat empty string as null, for example
			if (typeof raw === 'string') {
				return parseISO(raw);
			}

			throw new Error('invalid value to parse');
		},
	},
	DateTime: {
		serialize: (parsed: unknown): string | null =>
			parsed instanceof Date ? formatISO(parsed) : null,
		parseValue: (raw: unknown): Date | null => {
			if (!raw) return null; // if for some reason we want to treat empty string as null, for example
			if (typeof raw === 'string') {
				return parseISO(raw);
			}

			throw new Error('invalid value to parse');
		},
	},
	UUID: {
		serialize: (parsed: unknown): string | null => {
			if (typeof parsed !== 'string') return null;
			const r =
				/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;
			if (r.test(parsed)) return parsed;
			throw new Error('invalid value to serialize');
		},
		parseValue: (raw: unknown): string | null => {
			if (!raw) return null; // if for some reason we want to treat empty string as null, for example
			if (typeof raw === 'string') {
				return raw;
			}

			throw new Error('invalid value to parse');
		},
	},
	Color: {
		serialize: (parsed: unknown): string | null => {
			if (typeof parsed !== 'string') return null;
			const r = /^[a-f0-9]{6}$/i;
			if (r.test(parsed)) return parsed;
			throw new Error('invalid value to serialize');
		},
		parseValue: (raw: unknown): string | null => {
			if (!raw) return null; // if for some reason we want to treat empty string as null, for example
			if (typeof raw === 'string') {
				return raw;
			}

			throw new Error('invalid value to parse');
		},
	},
	Time: {
		serialize: (parsed: unknown): string | null => {
			if (typeof parsed !== 'string') return null;
			const r = /^([01]?\d|2[0-3]|24(?=:00?$)):([0-5]\d)$/;
			if (r.test(parsed)) return parsed;
			throw new Error('invalid value to serialize');
		},
		parseValue: (raw: unknown): string | null => {
			if (!raw) return null; // if for some reason we want to treat empty string as null, for example
			if (typeof raw === 'string') {
				return raw;
			}

			throw new Error('invalid value to parse');
		},
	},
};
