export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[];
	};
}
const result: PossibleTypesResultData = {
	possibleTypes: {
		Action: ['ArchiveFile', 'Task'],
		ArchiveEntry: ['ArchiveFile', 'ArchiveNote'],
		DeclarationBase: ['Declaration', 'Timer'],
		EntityLink: [
			'Article',
			'Order',
			'Organisation',
			'Person',
			'Project',
			'Task',
		],
		LayoutComponent: [
			'LayoutComponentBridge',
			'LayoutComponentGeneral',
			'LayoutComponentSpecificField',
		],
		Notification: [
			'BirthdayNotification',
			'CalendarEventPlannedNotification',
			'CalendarEventRemovedNotification',
			'CalendarOverdueNotification',
			'CalendarUpcomingEventNotification',
			'LeaveAcceptedNotification',
			'LeaveDeclinedNotification',
			'LeavePendingNotification',
			'LeaveToBeAssessedNotification',
		],
		PublicData: [
			'Article',
			'Order',
			'Organisation',
			'Person',
			'Project',
			'Task',
		],
		PublicDataArticle: ['Article'],
		PublicDataOrganisation: ['Organisation'],
		PublicDataPerson: ['Person'],
		PublicDataProject: ['Project'],
		PublicDataRelation: ['Organisation', 'Person'],
		Relation: ['Organisation', 'Person'],
		RelationBasePersonInputFieldSettings: [],
		RelationInputFieldSettings: [
			'RelationContactInputFieldSettings',
			'RelationNaturalPersonInputFieldSettings',
			'RelationOrganisationInputFieldSettings',
		],
		SpecificField: [
			'BooleanSpecificField',
			'DateSpecificField',
			'LinkSpecificField',
			'NumberSpecificField',
			'RootValueSpecificField',
			'StringSpecificField',
		],
		SpecificFieldObject: [
			'SpecificFieldSingleObject',
			'SpecificFieldTableObject',
		],
	},
};
export default result;
